import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dexie from 'dexie';
import { Workbox } from 'workbox-window';

import { UseToastProvider } from '@motorway/motorway-storybook-cra';

import { authenticate } from '../../utilities/api';
import { isMobileDevice } from '../../utilities/deviceHelpers';
import { chromeVersion, debounce, getVRM, isIOS, PHOTOS_PATH, redirectToWebApp } from '../../utilities/helpers';
import { captureDatadogException } from '../../utilities/logger';
import { Context } from '../context/context';
import { PhotosProvider } from '../context/photos';
import { SocketProvider } from '../context/Socket/Socket';

import { withBreakpointContext } from './Breakpoints/Breakpoints';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import { GeneralErrorScreen } from './GeneralErrorScreen/GeneralErrorScreen';
import Header from './Header/Header';
import Home from './Home/Home';
import AppLoading from './Loading/AppLoading';
import NoUser from './NoUser/NoUser';
import Obsolete from './Obsolete/Obsolete';
import { ScrollToTop } from './ScrollToTop/ScrollToTop';
import Toast from './Toast/Toast';
import VRM from './VRM/VRM';

import '@motorway/mw-highway-code/src/styles/theme/light.scss';
import styles from './App.module.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      featureFlags: {},
      imageToReplace: null,
      isLeftMenuOpen: false,
      isVideoVisible: false,
      loading: true,
      onboardingLoader: true,
      panel: true,
      showDamageLocationOverlay: false,
      showGlobalMessage: null,
      toast: null,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    const token = new URLSearchParams(window.location.search).get('token');

    this.setState({ token });

    const removeLoading = (obsolete) => this.setState({
      loading: false,
      obsolete,
    });

    const vrm = getVRM(window.location.pathname) || '';

    const mobileDevice = isMobileDevice({ includeTablet: false });

    // Don't want to have to support non-touch events
    if ((!mobileDevice) && vrm?.length) {
      return redirectToWebApp(vrm, (token || ''), PHOTOS_PATH);
    }

    if ((!mobileDevice) && !vrm?.length) {
      return removeLoading({ isNonTouch: true });
    }

    const matchUA = (regex) => regex.test(navigator.userAgent);

    const isWebView = matchUA(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i);

    // IOS browsers
    const isGoogleSearchApp = matchUA(/GSA\/[0-9]+/i);
    const isFirefoxIOS = matchUA(/FxiOS/i);

    if (isGoogleSearchApp || isFirefoxIOS) {
      return removeLoading({ unsupportedBrowserIOS: true });
    }

    // Android browsers
    const isFirefoxAndroid = matchUA(/Gecko.*Firefox/i);
    const isEdgeAndroid = matchUA(/EdgA/i);
    const isFocusAndroid = matchUA(/Focus/i);
    const isDuckDuckGoAndroid = matchUA(/Android.*DuckDuckGo/i);

    if (isFirefoxAndroid || isEdgeAndroid || isFocusAndroid || isDuckDuckGoAndroid) {
      return removeLoading({ unsupportedBrowserAndroid: true });
    }

    if (isWebView) {
      return removeLoading({ isWebView: true });
    }

    // Pretty bad bug in Android Chrome 77 and under where webm don't do transparency,
    // It's fixed in Chrome 78
    // https://stackoverflow.com/questions/58310790/android-chrome-webm-lose-transparency-over-a-certain-pixel-size
    const chrome = chromeVersion();

    if (matchUA(/Android/i) && Number.isInteger(chrome) && chrome <= 77) {
      return removeLoading({ isAndroidChromeLTE77: true });
    }

    // TODO - Add `MediaCapabilities.decodingInfo()` once the video is final to detect alpha support
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaCapabilities/decodingInfo
    // https://w3c.github.io/media-capabilities/#videoconfiguration
    const canUseGetUserMedia = navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    if (!canUseGetUserMedia) {
      return removeLoading({ obsolete: true });
    }

    // iOS 12 doesn't support `visualViewport`
    if (!window.visualViewport) {
      return removeLoading({ obsolete: true });
    }

    if (isIOS() && !navigator.cookieEnabled) {
      return removeLoading({ cookiesBlockedIOS: true });
    }

    const testIfPrivateDB = new Dexie('motorway-detect-private');
    testIfPrivateDB.version(1).stores({
      test: 'id++',
    });

    // Safari hasn't plugged the hole where if you try to set a blob URL in private mode it will fail
    const isPrivate = await testIfPrivateDB.test.add({ test: new Blob([JSON.stringify({ hello: 'world' }, null, 2)], { type: 'application/json' }) })
      .then(() => false)
      .catch((err) => {
        captureDatadogException({
          error: err,
          fingerprint: 'ios-private-mode',
          isWarning: true,
        });

        return true;
      });

    testIfPrivateDB.close();

    if (isPrivate) {
      return removeLoading({ isPrivate: true });
    }

    const params = new URLSearchParams(window.location.search);
    const debug = params.get('debug');

    this.setState({
      debug: (debug === 'true'),
    });

    const mq = window.matchMedia('(orientation : landscape)');
    const mqListener = () => this.setState({
      isLandscape: mq.matches,
    });
    mqListener();
    mq.addListener(mqListener);

    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/service-worker.js');

      wb.addEventListener('installed', (event) => {
        console.log('SW installed: ', event);
        if (event.isUpdate) {
          this.setState({
            toast: 'refresh',
          });
        }
      });

      wb.register().then((e) => {
        console.log('SW registered: ', e.active);
      }).catch((err) => {
        captureDatadogException({
          error: err,
          fingerprint: 'sw-registration',
          isWarning: true,
        });
        console.error('SW error: ', err);
      });
    }

    // So by default body is set to `touch-action: pan-x pan-y;` to prevent pinch zoom
    // However iOS occasionally will zoom in on rotation without user interaction
    // so we want to add a `zoomed` class which will allow `pinch-zoom` in the touch-actions
    // otherwise a user would never be able to zoom out

    const initialScale = window.visualViewport.scale; // See if the viewport is zoomed in
    const $body = document.body;
    const zoomedClassName = 'zoomed'; // Allows pinch-zoom in `body { touch-action }`

    if (initialScale > 1) {
      $body.classList.add(zoomedClassName);
    }

    let scale = initialScale;

    window.visualViewport.addEventListener('resize', debounce(() => {
      const newScale = window.visualViewport.scale;
      $body.classList[(newScale > 1.01) ? 'add' : 'remove'](zoomedClassName);

      if (scale >= newScale) {
        return;
      }

      scale = newScale;

      window.dataLayer.push({
        event: 'UAEvent',
        eventAction: 'Screen Zoom Changed',
        eventCategory: 'General',
        eventLabel: newScale.toString(),
      });
    }, 1000), {
      passive: true,
    });

    return authenticate(token).then((seller) => {
      this.setState({
        loading: false,
        seller: seller || false,
      });
    });
  }

  setParentState = (state, cb) => {
    this.setState(state, cb);
  };

  updateVehicleCondition = (condition) => {
    const { vehicleDetails } = this.state;
    const localId = `conditionState-${vehicleDetails.id}`;
    const localData = JSON.parse(localStorage.getItem(localId) || '{}');

    localStorage.setItem(localId, JSON.stringify({
      ...localData,
      ...condition,
      updatedAt: Date.now(),
    }));

    this.setState({
      vehicleDetails: {
        ...vehicleDetails,
        ...condition,
      },
    });
  };

  render() {
    const {
      createVideo,
      debug,
      error,
      featureFlags,
      imageToReplace,
      isLandscape,
      isLeftMenuOpen,
      isStatus404,
      isUploadingFinished,
      isVideoVisible,
      loading,
      obsolete,
      offer,
      onboardingLoader,
      panel,
      panelDamageInfo,
      prevOutstandingUploads,
      scrollDownHintSeen,
      seller,
      showDamageLocationOverlay,
      showGlobalMessage,
      toast,
      token,
      vehicleDetails,
      video,
      videoInfo,
    } = this.state;
    const { add: toastAdd, remove: toastRemove } = this.context;

    const {
      setParentState,
      updateVehicleCondition,
    } = this;

    let content;
    let toastMessage;

    if (loading) {
      return (
        <AppLoading />
      );
    }

    if (error?.errorHappened) {
      return (
        <GeneralErrorScreen
          {...error.message}
        />
      );
    }

    if (obsolete) {
      content = (
        <>
          <Header />
          <Obsolete
            obsolete={obsolete}
            seller={seller}
            token={token}
          />
        </>
      );
    } else {
      if (toast) {
        toastMessage = (
          <Toast action={toast} />
        );
      }

      content = (
        <>
          <Context.Provider
            value={{
              createVideo,
              debug,
              featureFlags,
              imageToReplace,
              isLandscape,
              isLeftMenuOpen,
              isStatus404,
              isUploadingFinished,
              isVideoVisible,
              offer,
              onboardingLoader,
              panel,
              panelDamageInfo,
              prevOutstandingUploads,
              scrollDownHintSeen,
              seller,
              setParentState,
              showDamageLocationOverlay,
              showGlobalMessage,
              toast,
              toastAdd,
              toastRemove,
              updateVehicleCondition,
              vehicleDetails,
              video,
              videoInfo,
            }}
          >
            <SocketProvider
              enquiryId={offer?.id}
              featureFlags={featureFlags}
              sellerAuthToken={seller?.auth_token}
            >
              <UseToastProvider>
                <PhotosProvider>
                  <ScrollToTop>
                    <Header />
                    {toastMessage}
                    <Routes>
                      <Route path="/">
                        <Route
                          index
                          element={token && !seller ? <NoUser /> : <Home />}
                        />
                        <Route Component={VRM} path=":vrm/*" />
                      </Route>
                    </Routes>
                  </ScrollToTop>
                </PhotosProvider>
              </UseToastProvider>
            </SocketProvider>
          </Context.Provider>
        </>
      );
    }

    return (
      <ErrorBoundary>
        <BrowserRouter>
          <div className={styles.app}>
            {content}
          </div>
        </BrowserRouter>
      </ErrorBoundary>
    );
  }
}

export default hot(withBreakpointContext(App));
